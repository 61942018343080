<template>
	<div>
    <Loader v-if="performingRequest" />
    <div class="dashboard__container pt-3">
      <div class="dashboard__container--body mb-5 flex flex-column">
        <h4 style="color:white;" class="mb-2">Shifts and Hired Workers:</h4>
        <div class="sCard" v-for="(item, index) in eventShifts" :key="index + item.id">
          <h3>{{item.title}} - {{item.staff}} Person
            <span class="ml-2 danger" v-if="item.staffingStatus && item.staffingStatus == 'Closed'">- ({{item.staffingStatus}})</span>
            <span class="ml-2 success" v-else-if="item.staffingStatus && item.staffingStatus == 'Filled'">- ({{item.staffingStatus}})</span>
            <span v-else class="ml-2">- ({{item.staffingStatus}})</span>
          </h3>
          <div v-if="item.position">Position: {{item.position.title}}</div>
          <div class="strong mt-3">Hired Staff</div>
          <div v-for="(item, index) in orderedPlacedUsers(item)" :key="index + item.id">
            <span v-if="item.firstName">{{item.firstName}}</span><span v-if="item.lastName" class="ml-2">{{item.lastName}}</span>
            <span v-if="(!item.firstName || !item.lastName) && item.fullName">{{item.fullName}}</span>
             / <span v-if="item.phone">{{item.phone}}</span> / <span v-if="item.day">Start Date: {{item.day | moment("MM/DD/YYYY")}}</span>
          </div>
          <div>
            <div class="strong mt-3">Shift Schedule</div>
            <div class="caption" v-if="item && item.sundayStart && item.sundayEnd">
              Sunday: {{formatAMPM(item.sundayStart)}} - {{formatAMPM(item.sundayEnd) | moment("h:mm:ss a") }}
            </div>
            <div class="caption" v-if="item && item.mondayStart && item.mondayEnd">
              Monday: {{formatAMPM(item.mondayStart)}} - {{formatAMPM(item.mondayEnd) | moment("h:mm:ss a") }}
            </div>
            <div class="caption" v-if="item && item.tuesdayStart && item.tuesdayEnd">
              Tuesday: {{formatAMPM(item.tuesdayStart)}} - {{formatAMPM(item.tuesdayEnd) | moment("h:mm:ss a") }}
            </div>
            <div class="caption" v-if="item && item.wednesdayStart && item.wednesdayEnd">
              Wednesday: {{formatAMPM(item.wednesdayStart)}} - {{formatAMPM(item.wednesdayEnd) | moment("h:mm:ss a") }}
            </div>
            <div class="caption" v-if="item && item.thursdayStart && item.thursdayEnd">
              Thursday: {{formatAMPM(item.thursdayStart)}} - {{formatAMPM(item.thursdayEnd) | moment("h:mm:ss a") }}
            </div>
            <div class="caption" v-if="item && item.fridayStart && item.fridayEnd">
              Friday: {{formatAMPM(item.fridayStart)}} - {{formatAMPM(item.fridayEnd) | moment("h:mm:ss a") }}
            </div>
            <div class="caption" v-if="item && item.saturdayStart && item.saturdayEnd">
              Saturday: {{formatAMPM(item.saturdayStart)}} - {{formatAMPM(item.saturdayEnd) | moment("h:mm:ss a") }}
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>

.dashboard {
  background: rgb(38 47 61 / 93%) !important;
  padding-top: 2rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - 6rem);
}
.fa-2x {
  font-size: 2rem;
}
h5 {
  font-size: 1.6rem;
  opacity: 1;
}
input {
  font-size: 1.4rem;
  background: #eee;
  border: none;
}
/* .btn__flat {
  color: white;
} */
.dashboard__container--header {
  align-items: flex-start;
}

.cancelled h5 {
    color: red !important;
  }
  .dashboard__container {
    max-width: 90%;
  }
</style>




<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'

export default {
  name: 'Shifts',
  data() {
    return {
      performingRequest: false,
    }
  },
  created() {
    this.$store.dispatch('getEventShifts', this.$route.params.id)
    this.$store.dispatch('getEventUsers', this.$route.params.id)
    // this.$store.dispatch('getEventFromIdAss', this.$route.params.id)
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'eventInfo', 'eventShifts', 'eventUsers']),
    filteredPlacedUsers () {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees')) {
      return this.eventUsers.filter(user => {
          return ((user.status == 'placed') || (user.status == 'assigned'))
        })
      }  else {
        return []
      }
    },
    
  },
  methods: {
    orderedPlacedUsers(shift) {
      console.log(shift)
      return this.filteredPlacedUsers.filter(user => {
        return user.shift == shift.id
      });
    },
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else if (date === "12:00") { 
          return `12:00 PM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatDate(q) {
      console.log(q)
      return moment(q).format('MM/DD/YYYY')
    },
    
   },
  beforeDestroy () {
    this.$store.dispatch('clearEventShifts')
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>